import { useEffect, useState } from 'react';

// material-ui
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';

// project imports
import Image from '../../../ui-component/Image';

import { useSelector } from 'react-redux';

import { getSingleStory } from 'api';

import config from '../../../config';

import RichText from 'editables/richtext';

import { contentWidth } from 'store/constant';

import OrderForm from './OrderForm';

const ZoomDeliveredPage = () => {
    const current_lang = useSelector((state) => state.customization.locale);
    const [pageFromCMS, setPageFromCMS] = useState();

    useEffect(() => {
        const getStory = async () => {
            const cc = config.currencyCode === 'CAD' ? 'ca' : 'us';
            const full_locale = cc === 'en' ? 'undefined' : `${current_lang}-${cc}`;
            const page = await getSingleStory('pages', '/zoom-delivered', { language: full_locale });
            setPageFromCMS(page);
            console.log(page);
        }
        getStory();
    }, [current_lang])

    return (
        <Box mt={8} mb={8} sx={{ maxWidth: contentWidth, marginLeft: 'auto', marginRight: 'auto'}}>
            <Grid container spacing={{xs:0, md:2}} p={2}>

                <Grid item md={5} xs={12}>
                    <Image
                        src={pageFromCMS?.content.image.filename}
                        alt={pageFromCMS?.content.name}
                        styles={{ width: 'auto', maxWidth: '100%', objectFit: 'contain', padding: 0 }}
                    />
                </Grid>

                <Grid item md={7} xs={12}>
                    <Grid container direction='column' sx={{ minHeight: '280px' }}>
                        <Grid item>
                            <Typography variant="paragraph" sx={{ maxWidth: '100%'}}>
                                {!!pageFromCMS && <RichText blok={pageFromCMS?.content.text} />}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12} xs={12} mt={6}>
                    <OrderForm></OrderForm>
                </Grid>

            </Grid>
        </Box>
    )
};

export default ZoomDeliveredPage;
