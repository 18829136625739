import { render, NODE_HEADING } from 'storyblok-rich-text-react-renderer';

// Define a custom resolver for heading1 nodes
const nodeResolvers = {
    [NODE_HEADING]: (children) => (
      <h1 style={{ lineHeight: '120%' }}>{children}</h1>
    ),
};

const RichText = ({ blok, className }) => {    
    return <div className={className} >{render(blok, { nodeResolvers })}</div>;
};

export default RichText