import { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
// material-ui
import {
    Button,
    Box,
    FormControl,
    FormControlLabel,
    Checkbox,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Typography,
    Stack,
    Link,
} from '@mui/material';

import axios from 'axios';

import { useTheme } from "@mui/material/styles";

// project imports
import Image from '../../../ui-component/Image';

import { useSelector } from 'react-redux';

import { getStories } from 'api';
import config from '../../../config';

import { FormattedMessage, useIntl } from "react-intl";

import * as Yup from 'yup';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import MainCard from 'ui-component/cards/MainCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { divide } from 'lodash';

import OrderComplete from '../thankyou/OrderComplete';

const OrderForm = () => {
    const maintenance = useSelector((state) => state.shop.maintenance);
    const { company } = useAuth();
    const current_lang = useSelector((state) => state.customization.locale);
    const [zoomDeliveredProducts, setZoomDeliveredProducts] = useState();
    const intl = useIntl();
    const { zd_order } = useAuth();
    const dispatch = useDispatch();
    const [step, setStep] = useState('start');
    const theme = useTheme();
    const formGrid = useRef(null);
    const [countryCode, setCountryCode] = useState('');
    const [addressValidated, setAddressValidated] = useState(true);
    const [cityKey, setCityKey] = useState(Math.random());
    const [stateKey, setStateKey] = useState(Math.random());
    const [orderStatus, setOrderStatus] = useState();
    const [orderLoading, setOrderLoading] = useState(true);
    const [orderInfo, setOrderInfo] = useState();
    const [openOrderConfirmation, setOpenOrderConfirmation] = useState(false);

    const payment = {
        status: orderStatus,
        isLoading: orderLoading,
        zoomDelivered: true,
        orderInfo: orderInfo
    }

    //TODO get the scanners from Storyblok?
    const scanners = maintenance?.scanner_order

    const formik = useFormik({
        initialValues: {
            email: company?.Email ? company?.Email : company?.cust_name,
            first_name: '',
            last_name: '',
            zip: '',
            street: '',
            number: '',
            city: '',
            state: '',
            delivered_products: '',
            scanner: '',
            patient_scan_id: '',
            country: config.currencyCode === 'CAD' ? 'Canada' : 'United States',
            en: '',
            fr: '',
            language: current_lang,
            terms_use: config.currencyCode === 'CAD' ? false : true,
            terms_conditions: config.currencyCode === 'CAD' ? false : true,
            info_correct: false,
            net_terms: false,
            url: config.currencyCode === 'CAD' ? 'https://proshop.philips.ca/' : 'https://proshop.philips.com/',
            submit: null,
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required(),
            last_name: Yup.string().required(),
            zip: config.currencyCode === 'CAD' ?
                Yup.string().min(3).required()
                :
                Yup.string().min(5).required(),
            street: Yup.string().required(),
            number: Yup.string(),
            city: Yup.string().required(),
            state: Yup.string().required(),
            delivered_products: Yup.string().required(),
            scanner: Yup.string().required(),
            patient_scan_id: Yup.string().required(),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                setOrderLoading(true);
                setOrderStatus('CREATED');
                setOpenOrderConfirmation(true);
                const resp = await zd_order(values);
                setStatus({ success: resp.success });
                setSubmitting(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: resp.message,
                    variant: 'alert',
                    autoHideDuration: null,
                    alertSeverity: resp.success ? 'success' : 'error'
                });

                if(resp.success === true){
                    setOrderInfo({
                        firstName: formik.values.first_name,
                        lastName: formik.values.last_name,
                        street: formik.values.street,
                        number: formik.values.number,
                        city: formik.values.city,
                        state: formik.values.state,
                        zip: formik.values.zip,
                        country: formik.values.country,
                        product: formik.values.delivered_products
                    });
                    setOrderStatus('COMPLETED');
                    setOrderLoading(false);
                    setStep('start');
                    resetForm();
                    window.scrollTo({ top:  0, left:  0, behavior: 'smooth' });
                }
            } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setOrderLoading(false);
            }
        },
    });

    useEffect(() => {
        if(current_lang === 'en'){
            formik.setFieldValue('en', 'english');
            formik.setFieldValue('fr', '');
        }
        if(current_lang === 'fr'){
            formik.setFieldValue('en', '');
            formik.setFieldValue('fr', 'french');
        }
        formik.setFieldValue('language', current_lang.toUpperCase());
    }, [current_lang])

    const validateAddress = async (address) => {
        const response = await axios.post("https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyADrrKoi_84ovwT3RqnhEzh-5S9hOif-t4", { address });
        let resp = response.data;
        if (resp.result.verdict.geocodeGranularity === 'PREMISE' && resp.result.verdict.inputGranularity === 'PREMISE' && resp.result.verdict.validationGranularity === 'PREMISE') {
            setAddressValidated(true);
        } else {
            if (!!formik.values.number && !!formik.values.street && !!formik.values.city && !!formik.values.state && !!formik.values.zip && !!formik.values.email && !!formik.values.phone) {
                setAddressValidated(false);
            }
        }
        return resp;
    };

    const updateAddress = () => {
        let newAddress = {
            regionCode: config.currencyCode === 'CAD' ? 'CA' : 'US',
            addressLines: [formik.values.number + ' ' + formik.values.street, formik.values.city, formik.values.state, formik.values.zip]
        }
        validateAddress(newAddress);
    }

    useEffect(() => {
        updateAddress()
    }, [formik.values])

    const openForm = () => {
        setStep('address');
        formGrid.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const openConfirmation = () => {
        setStep('confirmation')
        formGrid.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const handleZipChange = async (e) => {
        try {
            const response = await axios.get(`http://api.zippopotam.us/${countryCode}/${e.target.value}`);
            formik.setFieldValue('city', response.data.places[0]['place name']);
            setCityKey(Math.random());
            formik.setFieldValue('state', response.data.places[0].state);
            setStateKey(Math.random());
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        const getProducts = async () => {
            const cc = config.currencyCode === 'CAD' ? 'ca' : 'us';
            setCountryCode(cc);
            const full_locale = cc === 'en' ? 'undefined' : `${current_lang}-${cc}`;
            const products = await getStories({ language: full_locale, filter_query: { component: { like: 'zoom-delivered-product' } } });
            setZoomDeliveredProducts(products);
        }
        getProducts();
    }, [current_lang])

    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ]

    const provinces =[
        "Alberta", "British Columbia", "Labrador", "Manitoba", "New Brunswick", "Newfoundland", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan"
    ]

    return (
        <Box sx={{ maxWidth: '100%' }}>

            <OrderComplete setOpenOrderConfirmation={setOpenOrderConfirmation} open={openOrderConfirmation} payment={payment} isLoading={orderLoading} orderInfo={orderInfo} />

            <Grid container sx={{ maxWidth: "100%", marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid container spacing={gridSpacing} mb={12}>
                    <Grid item xs={6}>
                        <Button
                            component={NavLink}
                            to="/faq"
                            disableElevation
                            fullWidth
                            size="large"
                            variant={'contained'}
                            color="primary"
                        >
                            <FormattedMessage id="delivered.more-info" />
                        </Button>
                    </Grid>
                    {step === "start" &&
                        <Grid item xs={6}>
                            <Button
                                disableElevation
                                fullWidth
                                size="large"
                                variant={'contained'}
                                color="secondary"
                                onClick={() => openForm()}
                            >
                                <FormattedMessage id="delivered.start-wizard" />
                            </Button>
                        </Grid>
                    }
                </Grid>

                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={gridSpacing} mb={12} ref={formGrid}>
                        {step === "address" &&
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h2">
                                        <FormattedMessage id="delivered.form-title" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: '15px', textAlign: 'center' }}>
                                    <Typography variant="bold">
                                        <FormattedMessage id="delivered.form-description" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="delivered.first-name" /></InputLabel>
                                        <OutlinedInput type="text" label="First Name (Patient)"
                                            name='first_name'
                                            value={formik.values.first_name}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} />
                                        {formik.errors.first_name && formik.touched.first_name ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-first-name-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="delivered.last-name" /></InputLabel>
                                        <OutlinedInput type="text" label="Last Name (Patient)"
                                            name='last_name'
                                            value={formik.values.last_name}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} />
                                        {formik.errors.last_name && formik.touched.last_name ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-last-name-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="delivered.street" /></InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            label="Address line 1 (Patient)"
                                            name='street'
                                            value={formik.values.street}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} />
                                        {formik.errors.street && formik.touched.street ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-street-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="delivered.number" /></InputLabel>
                                        <OutlinedInput
                                            type="text"
                                            label="Address line 2 (Patient)"
                                            name='number'
                                            value={formik.values.number}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} />
                                        {formik.errors.number && formik.touched.number ? (<div style={{padding: '5px 15px 0px 15px'}}>{formik.errors.number}</div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="delivered.city" /></InputLabel>
                                        <OutlinedInput
                                            key={cityKey}
                                            type="text"
                                            label="City (Patient)"
                                            name='city'
                                            value={formik.values.city}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} />
                                        {formik.errors.city && formik.touched.city ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-city-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            fullWidth
                                            label={intl.formatMessage({ id: config.currencyCode === 'CAD' ? "delivered.province" : "delivered.state" })}
                                            value={formik.values.state}
                                            name='state'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {config.currencyCode === 'CAD' ?
                                                provinces.map((province, index) => (
                                                    <MenuItem key={index} value={province}>
                                                        {province}
                                                    </MenuItem>
                                                ))
                                            :
                                                states.map((state, index) => (
                                                    <MenuItem key={index} value={state}>
                                                        {state}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                        {formik.errors.state && formik.touched.state ?
                                            <div style={{padding: '5px 15px 0px 15px'}}>
                                                {config.currencyCode === 'CAD' ?
                                                    <FormattedMessage id="delivered.form-province-required"/>
                                                    :
                                                    <FormattedMessage id="delivered.form-state-required"/>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id={config.currencyCode === 'CAD' ? "delivered.postal" : "delivered.zip"} /></InputLabel>
                                        <OutlinedInput type="text" label={config.currencyCode === 'CAD' ? "Postal Code (Patient)" : "Zip Code (Patient)"}
                                            name='zip'
                                            value={formik.values.zip}
                                            onBlur={formik.handleBlur}
                                            // onChange={e => { formik.handleChange(e); handleZipChange(e); }}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.zip && formik.touched.zip ? 
                                            <div style={{padding: '5px 15px 0px 15px'}}>
                                                {config.currencyCode === 'CAD' ?
                                                    <FormattedMessage id="delivered.form-postal-required"/>
                                                    :
                                                    <FormattedMessage id="delivered.form-zip-required"/>
                                                }
                                            </div> 
                                            :
                                            null
                                        }
                                    </FormControl>
                                </Grid>
                                {!addressValidated &&
                                    <Grid item xs={12}>
                                        <Typography style={{ textAlign: 'center', width: '100%', padding: '15px' }}>
                                            Unknown address, please check your address details
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                        <TextField
                                            select
                                            fullWidth
                                            label={intl.formatMessage({ id: 'delivered.products' })}
                                            value={formik.values.delivered_products}
                                            name='delivered_products'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {zoomDeliveredProducts.filter(obj => obj.content.availability.includes(countryCode)).map((option, index) => (
                                                <MenuItem key={index} value={option.content.name}>
                                                    {option.content.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {formik.errors.delivered_products && formik.touched.delivered_products ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-product-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                        <TextField
                                            select
                                            fullWidth
                                            label={intl.formatMessage({ id: 'delivered.scanner' })}
                                            value={formik.values.scanner}
                                            name='scanner'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            {scanners.map((name, index) => (
                                                <MenuItem key={index} value={name}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <div style={{padding: '5px 15px 0px 15px'}}>
                                            <FormattedMessage id="delivered.scanner-not-listed" />
                                        </div>
                                        {formik.errors.scanner && formik.touched.scanner ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-scanner-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel><FormattedMessage id="delivered.patient-scan-id" /></InputLabel>
                                        <OutlinedInput type="text" label="Patient Scanner ID"
                                            value={formik.values.patient_scan_id}
                                            name='patient_scan_id'
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} />
                                        {formik.errors.patient_scan_id && formik.touched.patient_scan_id ? (<div style={{padding: '5px 15px 0px 15px'}}><FormattedMessage id="delivered.form-patient-scan-id-required" /></div>) : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} sx={{alignSelf:'center'}}>
                                    <Typography sx={{backgroundColor:'#F5F5F5', padding:'15px', borderRadius:'5px', fontSize:'16px', textAlign:'center'}}>
                                        <FormattedMessage id="delivered.send-to-dp-email" /> {
                                            company?.Email ? <b>{company?.Email}</b> : <b>{company.cust_name}</b>
                                        }
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {step === 'address' &&
                        
                            <Grid item xs={12} lg={6} sx={{alignSelf:'center'}}>
                                <Button
                                    disableElevation
                                    disabled={!addressValidated || Object.keys(formik.errors).length > 0 || formik.isSubmitting || !formik.isValid || formik.values.delivered_products.length < 1}
                                    fullWidth
                                    size="large"
                                    //other variant option is outlined
                                    variant={'contained'}
                                    color="primary"
                                    onClick={() => openConfirmation()}
                                >
                                    <FormattedMessage id="delivered.open-confirmation" />
                                </Button>
                            </Grid>

                        }

                        {step === 'confirmation' &&
                            <>
                                {/* TODO: use the AddressCard component instead of the code below */}
                                <Grid item xs={12} sx={{ marginBottom: '30px', textAlign: 'center' }}>
                                    <Typography variant="h2">
                                        <FormattedMessage id="delivered.order-summary-title" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={gridSpacing}>

                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" sx={{ pb: 1 }}>
                                                <FormattedMessage id="delivered.patient-details-header" />
                                            </Typography>
                                            <MainCard contentSX={{"&:last-child": { paddingBottom:  0 }}} border={true} elevation={2}>
                                                <Box sx={{ p: 2 }}>
                                                    <Stack spacing={0.5}>
                                                        <Typography variant="body2">
                                                            <b>{formik.values.first_name} {formik.values.last_name}</b>
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {`${formik.values.street}, ${formik.values.number}`}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {`${formik.values.city}, ${formik.values.state}`}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {formik.values.zip}, {formik.values.country}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </MainCard>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" sx={{ pb: 1 }}>
                                                <FormattedMessage id="delivered.scanner-details-header" />
                                            </Typography>
                                            <MainCard contentSX={{"&:last-child": { paddingBottom:  0 }}} border={true} elevation={2}>
                                                <Box sx={{ p: 2 }}>
                                                    <Stack spacing={0.5}>
                                                        <Typography>
                                                            {formik.values.scanner}: {formik.values.patient_scan_id}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </MainCard>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="subtitle1" sx={{ pb: 1 }}>
                                        <FormattedMessage id="delivered.selected-products" />
                                    </Typography>
                                    <Stack spacing={0.5}>
                                        {zoomDeliveredProducts.filter(obj => obj.content.availability.includes(countryCode)).filter(obj => obj.content.name === formik.values.delivered_products).map((product) => (
                                            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: '45px' }}>
                                                <Image
                                                    src={product?.content.image.filename}
                                                    alt={product?.content.name}
                                                    styles={{ width: '150px', height: '150px', objectFit: 'contain', padding: 0 }}
                                                />
                                                <Typography variant="subtitle1">
                                                    {product.content.name}
                                                </Typography>

                                                {/* <Typography variant="subtitle1">
                                                    ${product.content.price}
                                                </Typography> */}
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item md={6}></Grid>
                                        <Grid item md={6}>
                                            <Stack>
                                                <FormControl fullWidth>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                icon={<RadioButtonUncheckedIcon style={{color: '#ff00005e'}} />}
                                                                checkedIcon={<CheckCircleIcon color={'green'} />}
                                                                checked={formik.values.net_terms}
                                                                type="checkbox"
                                                                label="Net terms"
                                                                value={formik.values.net_terms}
                                                                name='net_terms'
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                            />
                                                        }
                                                        label={intl.formatMessage({ id: 'delivered.net-terms' })}
                                                    />
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                icon={<RadioButtonUncheckedIcon style={{color: '#ff00005e'}} />}
                                                                checkedIcon={<CheckCircleIcon color={'green'} />}
                                                                checked={formik.values.info_correct}
                                                                type="checkbox"
                                                                label="Info correct"
                                                                value={formik.values.info_correct}
                                                                name='info_correct'
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                            />
                                                        }
                                                        label={intl.formatMessage({ id: 'delivered.info-correct' })}
                                                    />
                                                </FormControl>
                                                {config?.currencyCode === 'CAD' &&
                                                <>
                                                    <FormControl sx="display: flex; flex-direction: row" fullWidth>
                                                        <FormControlLabel
                                                            sx={{display:'flex', marginRight: '3px'}}
                                                            control={
                                                                <Checkbox
                                                                    icon={<RadioButtonUncheckedIcon style={{color: '#ff00005e'}} />}
                                                                    checkedIcon={<CheckCircleIcon color={'green'} />}
                                                                    checked={formik.values.terms_use}
                                                                    type="checkbox"
                                                                    label="terms_use"
                                                                    value={formik.values.terms_use}
                                                                    name='terms_use'
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                            }
                                                            label={intl.formatMessage({ id: 'delivered.agree' })}
                                                        />
                                                        <Link href={config.links.terms_of_use.replace('${locale}', current_lang)} target="_blank" sx="display: flex; align-items: center;"><FormattedMessage id="delivered.the-terms-of-use"/></Link>
                                                    </FormControl>

                                                    <FormControl sx="display: flex; flex-direction: row" fullWidth>
                                                        <FormControlLabel
                                                            sx={{display:'flex', marginRight: '3px'}}
                                                            control={
                                                                <Checkbox
                                                                    icon={<RadioButtonUncheckedIcon style={{color: '#ff00005e'}} />}
                                                                    checkedIcon={<CheckCircleIcon color={'green'} />}
                                                                    checked={formik.values.terms_conditions}
                                                                    type="checkbox"
                                                                    label="terms_conditions"
                                                                    value={formik.values.terms_conditions}
                                                                    name='terms_conditions'
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                />
                                                            }
                                                            label={intl.formatMessage({ id: 'delivered.agree' })}
                                                        />
                                                        <Link href={config.links.terms_and_conditions.replace('${locale}', current_lang)} target="_blank" sx="display: flex; align-items: center;"><FormattedMessage id="delivered.the-terms-and-conditions"/></Link>
                                                    </FormControl>
                                                </>
                                                }
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={6} sx={{alignSelf: 'end'}}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => setStep('address')}
                                                >
                                                    <FormattedMessage id="delivered.back-button" />
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                        <Grid item xs={6} sx={{alignSelf: 'end'}}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting || !formik.isValid || formik.values.delivered_products.length < 1 || !formik.values.terms_use || !formik.values.terms_conditions || !formik.values.info_correct || !formik.values.net_terms }
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary">
                                                    <FormattedMessage id="delivered.send-button" />
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </form>
            </Grid>
        </Box>
    )
};

export default OrderForm;
