import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

const MaintenancePage = Loadable(lazy(() => import('views/pages/maintenance')));
const FaqPage = Loadable(lazy(() => import('views/pages/faq')));

// TODO; fix this, why is this not possible
// const SHOP_IS_OPEN = true; // TODO: we should call an API to hot switch ths shop in the future

// const MaintenanceRoutes = {
//     path: '/',
//     element: <MinimalLayout />,
//     children: [
//         {
//             path: '/faq/',
//             element: <FaqPage />
//         },                  
//         {
//             path: '/faq/:subject',
//             element: <FaqPage />
//         },   
//         {
//             path: '/',
//             element: <MaintenancePage/>            
//         },
//         {
//             path: '*',
//             element: <MaintenancePage/>            
//         }]
// };
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    return useRoutes([MainRoutes, LoginRoutes, AuthenticationRoutes]);
    // return useRoutes([MaintenanceRoutes]);
}
