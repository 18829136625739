// material-ui
import { Box, Container, Typography } from '@mui/material';
// import { NavLink } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import { FormattedMessage } from 'react-intl';

// ==============================|| LOADER ||============================== //
const OrderOnBehalfBar = () => {

    const { isSalesRep, company, user_id } = useAuth();

    return <>
        {isSalesRep && <Box sx={{ position: 'sticky', top: '76px', zIndex: 1, backgroundColor: '#0071DB' }}><Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px' }}>
                {
                    company?.Name && <Typography color="white" variant="h3"><FormattedMessage id="order-on-behalf.on-behalf-of"/> <span style={{ fontWeight: 'bold', fontSize: '22px' }}>{company.Name}</span> <FormattedMessage id="order-on-behalf.sales-rep-logged-in"/><span style={{ fontWeight: 'bold' }}>{user_id}</span></Typography>
                }
                {!company && <Typography color="white" variant="h3"><FormattedMessage id="order-on-behalf.nav-banner" /></Typography>}
            </Box>
        </Container></Box>}
    </>
};

export default OrderOnBehalfBar;
