import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
import shopReducer from './shopReducer';
import accountReducer from './accountReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const appReducer = combineReducers({
    account: accountReducer,
    cart: cartReducer,
    snackbar: snackbarReducer,
    shop: shopReducer,
    customization: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'philips-'
        },
        customizationReducer
    ),
  })
  
  const reducer = (state, action) => {
    // console.log(action);
    if (action.type === 'LOGOUT') {
        return appReducer(undefined, action)
      }
    
      return appReducer(state, action)
  }

export default reducer;
