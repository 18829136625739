import { FILTER_ON_CATEGORYKEY, FILTER_ON_ATTRIBUTE, SET_CATEGORIES, SET_PRODUCTS, FILTER_BY_VALUE, SORT_BY, SET_STORY, SET_MAINTENANCE } from "./actions";
import { orderBy } from 'lodash';
import config from "../config";

// const initBundlesByLng = {};
// for( const l of config.spokenLanguages ){
//      initBundlesByLng[l] = [];
// };

// this is the same ...
const initBundlesByLng = config.spokenLanguages.reduce((acc,l)=> (acc[l]=[],acc),{});

// action - state management
const initialState = {
    initialized: false,
    categories: [],
    products: [],
    filteredProducts: [],
    categoryActive: undefined,
    categoryKeyActive: undefined,
    filters: [],
    bundlesFromCMSbyLng: initBundlesByLng,
    keyword: undefined
};

// ==============================|| SNACKBAR REDUCER ||============================== //

const shopReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            const categories = action.payload;

            return {
                ...state,
                categories: categories
            };
        case SET_PRODUCTS:
            const { products } = action;
            let filteredProducts = [];


            filteredProducts = filterProductsOnId(state.categoryKeyActive, products);

            return {
                ...state,
                initialized: true,
                products: products,
                filteredProducts: filteredProducts,
            }
        case SET_STORY: {
            debugger
            const { payload } = action;
            for( const lng in payload ) {
                for( const bundle of payload[lng] ) {
                    bundle.codes = bundle?.content?.alt_codes?.toUpperCase()?.split(',') ?? [];
                    bundle.codes.push(bundle?.content?.code?.toUpperCase() ?? "");
                }
            }
            return {
                ...state,
                bundlesFromCMSbyLng: payload
            }
        }
        case SET_MAINTENANCE: {
            const { payload } = action;

            return {
                ...state,
                maintenance: payload
            }
        }
        case FILTER_ON_ATTRIBUTE: {
            const { filter } = action;
            const newState = Object.assign({}, state);

            // set checked filters to filterlist in store
            for (const f of newState.filters) {
                if ((f.title === filter.title) && filter.checked) {
                    f.checked = true;
                    break;
                } else if ((f.title === filter.title) && !filter.checked) {
                    f.checked = false;
                }
            }

            // filter products on category if applicable
            const filteredProductsOnCat = filterProductsOnId(newState.categoryKeyActive, newState.products);
            // filter products on attributes, if applicable
            const filteredProductsOnAttributes = filterOnActiveCategories(newState.filters, filteredProductsOnCat);
            // apply text search
            newState.filteredProducts = filterOnKeywords(newState.keyword, filteredProductsOnAttributes);

            return newState

        }
        case FILTER_ON_CATEGORYKEY:
            let filteredState = Object.assign({}, state);
            const { id } = action;

            let filteredList = [];
            if (id === undefined) {
                filteredList = filteredState.products;
                filteredState.categoryActive = undefined;
            } else if (filteredState.products.length > 0) {
                filteredList = filterProductsOnId(id, filteredState.products);
            }

            // set an active category if there is one applicable
            if (filteredList.length > 0) {
                for (const cat of filteredList[0].categories) {
                    if (cat.key === id) {
                        filteredState.categoryActive = cat;
                        break;
                    }
                }
            }

            filteredState.categoryKeyActive = id;
            filteredState.filteredProducts = filteredList;
            filteredState.filters = buildFilterList([], filteredList);

            return filteredState

        case SORT_BY:
            const { field, direction } = action;
            const sortBy = Object.assign({}, state);
            let sortedArray = orderBy(sortBy.filteredProducts, [field], [direction])

            sortBy.filteredProducts = sortedArray;

            return sortBy;

        case FILTER_BY_VALUE:
            let newState = Object.assign({}, state);
            let filteredProductsOnCat, filteredProductsOnAttributes, filteredValues, filterList;

            // filter products on category if applicable
            filteredProductsOnCat = filterProductsOnId(state.categoryKeyActive, state.products);
            // filter products on attributes, if applicable
            if (!!action.value) {
                filteredProductsOnAttributes = filterOnActiveCategories(newState.filters, filteredProductsOnCat);
                // apply text search
                filteredValues = filterOnKeywords(action.value, filteredProductsOnAttributes);
                // pass previous filterlist in as well to prevent state loss (starting over with new list of filters);
                filterList = buildFilterList(newState.filters, filteredProductsOnCat, filteredValues);
            } else {
                // search value. Just set it equal to the category filter
                filteredValues = filterOnActiveCategories(newState.filters, filteredProductsOnCat);
                // do update filterlist please. Make sure count is reset to original list counts!
                filterList = buildFilterList(newState.filters, filteredProductsOnCat, filteredValues, true);
            }


            newState.keyword = action.value;
            newState.filters = filterList;
            newState.filteredProducts = filteredValues;
            return newState;
        default:
            return state;
    }
};

export default shopReducer;

function filterProductsOnId(id, products) {
    if (!id) {
        return products;
    } else {
        let filteredProducts = [];
        for (const prod of products) {
            for (const cat of prod.categories) {
                if (cat.key === id) {
                    filteredProducts.push(prod);
                    break;
                }
            }
        }
        return filteredProducts;
    }

}

function filterOnActiveCategories(filters, products) {
    let filteredProducts = [];
    let activeFilterList = [];
    // get array of what to filter on
    filters.forEach((filter) => {
        if (filter.checked) {
            activeFilterList.push(filter.title)
        }
    })
    // update filtered products    
    if (activeFilterList.length > 0) {
        filteredProducts = products.filter((prod) => { return prod.filters.some((attr) => activeFilterList.includes(attr)) })
    } else {
        filteredProducts = products;
    }

    return filteredProducts;
}

function filterOnKeywords(value, products) {
    let filteredValues = [];
    if (!!value) {
        filteredValues = products.filter(product => {
            const fields = [product.SKUName + product.SKUDescription + product.ctn + product.part_no + product.part_skey];
            let search_this = JSON.stringify(fields).toLowerCase(); // only search specific key/values, else it will get slow with many results
            return search_this.indexOf(value.toLowerCase()) > -1;
        });
    } else {
        filteredValues = products;
    }
    return filteredValues;
}

function buildFilterList(filterList, products, filteredProducts, resetCount) {
    // products is list of products shown on initial view
    if (filterList.length === 0 && !filteredProducts) {
        for (const prod of products) {
            if (prod?.filters) {
                for (const filter of prod.filters) {
                    if (filterList.filter(attr => attr.title === filter).length > 0) {
                        // found filter, ++ count
                        const i = filterList.findIndex(f => f.title === filter);
                        filterList[i].count++;
                    } else {
                        // first entry, push to list
                        const f = { title: filter, count: 1, checked: false }
                        filterList.push(f);
                    }
                }
            }
        }

    }

    // show same filterlist, only recount based on filterproductlist
    if (!!filteredProducts) {
        // reset counts
        for (const f of filterList) {
            f.count = 0;
        }
        // go through al filteredproducts
        for (const prod of resetCount ? products : filteredProducts) {
            if (prod?.filters) {
                for (const filter of prod.filters) {
                    if (filterList.filter(attr => attr.title === filter).length > 0) {
                        // found filter, ++ count
                        const i = filterList.findIndex(f => f.title === filter);
                        filterList[i].count++;
                    }
                }
            }
        }
    }
    filterList.sort((a, b) => a.title.localeCompare(b.title));
    return filterList;
}
