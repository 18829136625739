import ReactDOM from 'react-dom';
import './index.css';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store, persister } from 'store';
import { storyblokInit, apiPlugin } from "@storyblok/react";

// style + assets
import 'assets/scss/style.scss';
import config from 'config';

// components
// import EditableBundle from 'editables/richtext';

// ==============================|| REACT DOM RENDER  ||============================== //

const components = { 
    // bundle: EditableBundle
  }; 
  
  storyblokInit({
    accessToken: '8JYWY4F9u0Gtdvl7CazIpQtt',
    use: [apiPlugin],
    components,
    apiOptions: { region: 'us' } 
  });

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
