import TopNavigation from 'layout/MainLayout/TopNavigation';
import Footer from 'layout/MainLayout/Footer';
import { Outlet } from 'react-router-dom';
import useMaintenance from 'hooks/useMaintenance';
import MaintenancePage from 'views/pages/maintenance';
import BlockingLoader from 'ui-component/BlockingLoader';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = ({never_blocked=false}) => {
    const maintenance = useMaintenance();

    return(<>  
        <TopNavigation />
            {!!never_blocked ? <Outlet/> :<> 
                {!maintenance ? <BlockingLoader text="Loading ..." /> : !never_blocked && !!maintenance?.maintenance_mode ? <MaintenancePage/> : <Outlet />}
            </>
            }
        <Footer />
    </>);
};

export default MinimalLayout;
