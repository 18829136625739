import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import useAuth from 'hooks/useAuth';
import config from 'config';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, isSalesRep } = useAuth();
    const redirect = useSelector((state) => state.account.redirect);

    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            let target_path = config.defaultPath;
            if (!!isSalesRep) {
                target_path = '/order-on-behalf';
            }
            if (!!redirect) {
                target_path = redirect
            }
            navigate(target_path, { replace: true });
        }
    }, [isLoggedIn, navigate, isSalesRep]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
