// material-ui
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
});

// ==============================|| LOADER ||============================== //
const BlockingLoader = ({isLoading, text}) => (
    <>
       { isLoading && <LoaderWrapper>
            <Box sx={{ position: 'absolute', display: 'flex', height: '100vh', width: '100vw', top: 0, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255,255,255, 0.7)'}}>
                <CircularProgress disableShrink style={{ marginBottom: '20px' }} />
                <Typography>{!!text ? text : 'Loading...'}</Typography>
            </Box>
        </LoaderWrapper> }
    </>
);

export default BlockingLoader;
