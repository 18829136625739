import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, Divider, Grid, Stack, Box, Typography, Zoom, useMediaQuery } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

import { RESET_CART } from 'store/actions';
// third-party
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui-component/Loader';
import { FormattedMessage } from 'react-intl';
import config from '../../../config';


const Transition = forwardRef((props, ref) => <Zoom ref={ref} {...props} />);

// ==============================|| CHECKOUT CART - DISCOUNT COUPON CODE ||============================== //

const OrderComplete = ({ setOpenOrderConfirmation, open, payment, isLoading, orderInfo }) => {
    const theme = useTheme();
    const current_lang = useSelector((state) => state.customization.locale);

    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateToShop = () => {
        dispatch({ type: RESET_CART });
        navigate('/');
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            sx={{ padding: 0 }}
            PaperProps={{
                elevation: 0,
                sx: {
                    border: "solid 1px gray"
                }
            }}
        >
            <MainCard
                sx={{
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    overflow: 'auto',
                }}>
                <Grid container direction="column" spacing={gridSpacing} alignItems="center" justifyContent="center" sx={{ my: 0, p: 8, [theme.breakpoints.down('md')]: {p: 4}, [theme.breakpoints.down('sm')]: {p: 2}}}>
                    {isLoading && <><FormattedMessage id="wait" /><Loader /></>}
                    {!isLoading && !!payment &&
                        <Grid item xs={12}>
                            <Typography variant={matchDownMD ? 'h2' : 'h1'} align="center">
                                {payment.status === 'COMPLETED' && <FormattedMessage id={!!payment?.zoomDelivered ? "payment-completed-header-zoom-delivered" : "payment-completed-header"} />}
                                {payment.status !== 'COMPLETED' && <FormattedMessage id="payment-canceled-header" />}
                            </Typography>
                        </Grid>
                    }
                    {!isLoading && !!payment &&
                        <Grid item xs={12}>
                            <Stack alignItems="center" spacing={2}>
                                {!!payment?.zoomDelivered &&
                                    <Typography variant="h4" align="center">
                                        {!!payment?.orderInfo && 
                                            <Typography variant="h4" sx={{mb:2}}>
                                                {orderInfo.product}
                                            </Typography>
                                        }
                                        <Stack spacing={0.5}>
                                            <Typography variant="body1">
                                                {orderInfo.firstName} {orderInfo.lastName}
                                            </Typography>
                                            <Typography variant="body1">
                                                {`${orderInfo.street}, ${orderInfo.number}`}
                                            </Typography>
                                            <Typography variant="body1">
                                                {`${orderInfo.city}, ${orderInfo.state}`}
                                            </Typography>
                                            <Typography variant="body1">
                                                {orderInfo.zip}, {orderInfo.country}
                                            </Typography>
                                        </Stack>
                                    </Typography>
                                }
                                <Typography align="center" variant="h4" sx={{ fontWeight: 400, color: 'grey.500' }}>
                                    {payment.status === 'COMPLETED' && <FormattedMessage id={!!payment?.zoomDelivered ? "payment-completed-message-zoom-delivered" : "payment-completed-message"} />}
                                    {payment.status !== 'COMPLETED' && <FormattedMessage id="payment-canceled-message" values={{
                                        link: <NavLink to="/contact">support</NavLink>
                                    }} />}
                                </Typography>
                                {!payment?.zoomDelivered &&
                                    <Typography variant="body1" align="center">
                                        {!!payment?.purchase_units[0]?.payments?.captures[0] && <>order id:{' '}</>}
                                        <Typography variant="subtitle1" component="span" color="primary">                                        
                                            { payment?.purchase_units[0]?.payments?.captures[0]?.custom_id}
                                            {!payment?.purchase_units[0]?.payments?.captures[0]?.custom_id && payment?.order?.id }
                                        </Typography>
                                    </Typography>
                                }
                            </Stack>
                        </Grid>
                    }
                    {/* <Grid item xs={12}>
                        <Divider />
                    </Grid> */}
                    <Grid item xs={12}>
                        <Grid
                            direction={matchDownMD ? 'column-reverse' : 'row'}
                            container
                            spacing={3}
                            alignItems={matchDownMD ? '' : 'center'}
                            justifyContent="space-between"
                        >
                            {!!payment && !payment.zoomDelivered &&
                                <Grid item>
                                    <Button 
                                        disabled={isLoading}
                                        onClick={() => { navigateToShop() }}
                                        variant="contained"
                                        fullWidth
                                    >
                                        <FormattedMessage id="payment-back-to-shop" />
                                    </Button>                     
                                </Grid>
                            }
                            {!!payment && !!payment.zoomDelivered &&
                                <Grid item>
                                    <Button
                                        disabled={isLoading}
                                        onClick={() => { setOpenOrderConfirmation(false); }}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                    >
                                        <FormattedMessage id="payment-back-to-zoom-delivered" />
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button 
                                    disabled={isLoading}
                                    onClick={() => { navigate('/contact'); }}
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                >
                                    <FormattedMessage id="payment-customer-service" />
                                </Button>
                            </Grid>
                            { !!payment && payment.status !== 'CREATED' && !!payment.links && config.currencyCode !== 'CAD' && 
                            <Grid item>
                                <Button disabled={isLoading}
                                    variant="outlined"
                                    href={config.links.dp_marketing.replace('${locale}', current_lang)}
                                    target="_blank"
                                >
                                    <FormattedMessage id="payment-sub-to-newsletter" />
                                </Button>
                            </Grid> }
                            {!!payment && payment.status === 'CREATED' && !!payment.links &&
                                <Grid item>
                                    <Button disabled={isLoading} href={payment.links.find(l => l.rel === 'approve').href}
                                        onClick={() => { navigateToShop() }}
                                        variant="contained" fullWidth
                                    >
                                        <FormattedMessage id="shop.resume-payment" />
                                    </Button>
                                </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </MainCard>
        </Dialog>
    );
};

OrderComplete.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    payment: PropTypes.object,
    orderInfo: PropTypes.object,
    zoomDelivered: PropTypes.bool,
};

export default OrderComplete;
