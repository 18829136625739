import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { contentWidth } from 'store/constant';
// assets
import hero from 'assets/images/hero.png';

// ==============================|| 404 PAGE ||============================== //


const MaintenancePage = () => {
    const maintenance = useSelector((state) => state.shop.maintenance);
    useEffect(() => {

    }, []);

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', backgroundImage: 'linear-gradient(34deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%), url(' + hero + ')', backgroundSize: 'cover', height: '300px', width: '100%' }}>
                <Grid container sx={{ maxWidth: contentWidth, margin: '0 auto' }}>
                    <Grid item xs={5}>
                        <span style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.25)' }}><Typography id="page-not-found" variant="h1" color="white"></Typography></span>
                    </Grid>
                    <Grid item xs={7}></Grid>
                </Grid>
            </Box>
            <Box sx={{ maxWidth: contentWidth, margin: '75px auto' }}>
                <Box sx={{ marginBottom: '25px' }}>
                    <Typography variant="h1">
                        {maintenance?.title ?  <>{maintenance.title}</> :
                        <FormattedMessage id="maintenance-page.title" />}
                    </Typography>
                </Box>
                {maintenance?.message ? <Box sx={{ marginBottom: '25px' }}>
                    <p>&nbsp;</p>
                    <Typography dangerouslySetInnerHTML={{__html: maintenance.message}}></Typography>
                </Box> : <>
                    <Box sx={{ marginBottom: '25px' }}>
                        <Typography paragraph>
                            <FormattedMessage id="maintenance-page.text" />
                        </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '25px' }}>
                        <Typography paragraph>
                            <FormattedMessage id="maintenance-page.thanks" />
                        </Typography>
                    </Box>
                </>}
                
            </Box>
        </>
    )
};

export default MaintenancePage;
