// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Container, Link, Grid, Divider, Typography } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import { contentWidth } from 'store/constant';
import LogoMark from 'ui-component/LogoMark';
import MainNavigation from '../MainNavigation';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import config from '../../../config';

// ==============================|| MAIN  HEADER ||============================== //

const TopBar = () => {
    const current_lang = useSelector((state) => state.customization.locale);

    const theme = useTheme();
    const { logout, isLoggedIn, company, isSalesRep } = useAuth();
    const billonline_base = 'https://www.billonline.com/discusdental/validate.aspx';
    let billonline_hash;
    if (isLoggedIn && company && !isSalesRep && config.currencyCode !== 'CAD') {
        const token = window.localStorage.getItem('token');
        const sessionID = token.split('||').slice(-1)[0];
        const querystring = `a=${company.cust_no}&s=${sessionID}`;
        billonline_hash = btoa(querystring).replace(/[\=\+\/]/g, '');
    };
    return (
        <>
            {/* blue topbar */}
            <Box sx={{ display: 'flex', [theme.breakpoints.down('sm')]: { display:"none" }, alignItems: 'center', backgroundColor: theme.palette.primary.main, width: '100%' }}>
                <Container>
                    <Grid container sx={{ maxWidth: contentWidth, height: 48, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                            <NavLink to="/" style={{ color: theme.palette.primary.light, textDecoration: 'none', cursor: 'pointer' }}><FormattedMessage id="navigation-top.top-left-text" /></NavLink>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {!!billonline_hash && <Link sx={{ marginRight: '1em' }} target="_blank" href={`${billonline_base}?${billonline_hash}`} color={theme.palette.primary.light} underline='none'><FormattedMessage id="navigation-top.link-billpay" /></Link>}
                            <Link sx={{ marginRight: '1em' }} target="_blank" href={config.links.consumers.replace('${locale}', current_lang)} color={theme.palette.primary.light} underline='none'><FormattedMessage id="navigation-top.link-consumers" /></Link>
                            {isLoggedIn && <Typography color={theme.palette.primary.light} onClick={logout} sx={{ cursor: 'pointer' }}><FormattedMessage id="navigation-top.link-log-out" /></Typography>}
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* logo and navigation bar */}
            <Box sx={{ position: 'sticky', top: 0, backgroundColor: '#FFF', zIndex: 100 }}>
                <Container>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '75px', width: '100%', maxWidth: contentWidth, margin: '0 auto' }}>
                        <NavLink to="/">
                            <LogoMark />
                        </NavLink>
                        <MainNavigation />
                    </Box>
                </Container>
                <Divider />
            </Box>
        </>
    );
};

export default TopBar;
