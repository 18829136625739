
import { NavLink } from 'react-router-dom';
// material-ui
import { Box, Grid, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { contentWidth } from 'store/constant';
import logo from 'assets/images/logo.svg';
import LocalizationSection from '../Header/LocalizationSection/index'
import config from '../../../config';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN  HEADER ||============================== //

const Footer = () => {
    const current_lang = useSelector((state) => state.customization.locale);
    const { isLoggedIn } = useAuth();
    const theme = useTheme();
    const supportLinks = [
        { title: <FormattedMessage id="footer.column-one-link-one"/>, url: isLoggedIn ? '/contact' : '', extUrl: isLoggedIn ? '' : config.links.dp_sales.replace('${locale}', current_lang) }, 
        { title: <FormattedMessage id="footer.column-one-link-two"/> , url: '/faq' },
    ];

    if(config?.links?.terms_and_conditions){
        supportLinks.push({ 
            title: <FormattedMessage id="footer.column-one-link-tc"/>, 
            extUrl: config.links.terms_and_conditions.replace('${locale}', current_lang)
        })
    };

    if(config?.links?.terms_of_use){
        supportLinks.push({ 
            title: <FormattedMessage id="footer.column-one-link-tu"/>, 
            extUrl: config.links.terms_of_use.replace('${locale}', current_lang)
        })
    };

    return (
        <Box sx={{backgroundColor: theme.palette.primary.main, width: '100%', paddingTop: '93px', paddingBottom: '93px', overflow: 'hidden' }}>
            <Grid container direction={{ xs: 'column', sm:'row' }} sx={{ maxWidth: contentWidth, marginRight: 'auto', marginLeft: 'auto' }} spacing={2}>
                <Grid item xs={3}>
                    <img src={logo} height="76" alt="Philips Logo white" />
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="bold" color="primary.light">
                        <FormattedMessage id="footer.column-one-header"/>
                    </Typography>
                    <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        { supportLinks.map( (link, i) => {
                            if (link.url) { return  <NavLink key={i} style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} to={link.url}>{ link.title }</NavLink> }
                            else { return  <a key={i} target="_blank" style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} href={link.extUrl} rel="noreferrer">{ link.title }</a> }
                        })}
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="bold" color="primary.light">
                        <FormattedMessage id="footer.column-two-header"/>
                    </Typography>
                    <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {config.currencyCode !== 'CAD' &&
                            <Link href={config.links.dp_marketing.replace('${locale}', current_lang)} color={'primary.light'} underline='none' target="_blank" style={{ marginBottom: 10 }}><FormattedMessage id="footer.column-two-link-one"/></Link>
                        }
                        <Link href={config.links.about.replace('${locale}', current_lang)} color={'primary.light'} underline='none' target="_blank" style={{ marginBottom: 10 }}><FormattedMessage id="footer.column-two-link-two"/></Link>
                        {config.links?.imported && <>
                            <Typography variant="bold" color="primary.light">
                                <FormattedMessage id="footer.column-two-imported"/>
                            </Typography>
                            {config.links.imported.href
                             ? <Link href={config.links?.imported?.href.replace('${locale}', current_lang)} color={'primary.light'} underline='none' target="_blank" style={{ marginTop: 10 }}>
                                {config.links.imported.company}
                              </Link>
                            : <Typography color="primary.light" style={{ marginTop: 10 }}>{config.links.imported.address} </Typography>
                            }
                            <Typography color="primary.light">{config.links.imported.address}</Typography>
                            <Typography color="primary.light">{config.links.imported.city}</Typography>
                            <Typography color="primary.light">{config.links.imported.phone}</Typography>    
                        </>}
                        {config.links.produced && <>
                            { config.links?.produced?.href 
                            ? <Link href={config.links.produced.href.replace('${locale}', current_lang)} color={'primary.light'} underline='none' target="_blank" style={{ marginTop: 10 }}>
                                {config.links.produced.company}
                             </Link>
                             : <Typography color="primary.light" style={{ marginTop: 10 }}>{config.links.produced.company}</Typography>
                            }

                            <Typography color="primary.light">{config.links.produced.address}</Typography>
                            {/* <Typography color="primary.light">{config.links.imported.city}</Typography> */}
                            {/* <Typography color="primary.light">{config.links.imported.phone}</Typography>     */}
                        </>}
                    </Box>
                    <span style={{ color: 'rgb(0, 43, 92)'}}>v new api</span>
                </Grid>
                {config.spokenLanguages.length > 1 && <Grid item xs={3}>
                    <Typography variant="bold" color="primary.light">
                        <FormattedMessage id="footer.column-three-header"/>
                    </Typography>
                    <Box mt={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <LocalizationSection nav={false} />
                    </Box>
                </Grid>}

            </Grid>
            <Grid container direction={{ xs: 'column', sm:'row' }} sx={{ maxWidth: contentWidth, marginTop: '100px', marginRight: 'auto', marginLeft: 'auto' }} spacing={2}>
                <Grid item xs={8}>
                    {/* <Typography variant="extraSmall" color="primary.light" mr={2}>
                    <FormattedMessage id="footer.bottom-left-link-one"/>
                    </Typography>
                    <Typography variant="extraSmall" color="primary.light" mr={2}>
                        <a target="_blank" style={{color: theme.palette.primary.light, textDecoration: 'none', marginBottom: 10 }} href={supportLinks[2].extUrl} rel="noreferrer">
                            <FormattedMessage id="footer.bottom-left-link-two"/>
                        </a>

                    </Typography>
                    <Typography variant="extraSmall" color="primary.light" mr={2}>
                        <FormattedMessage id="footer.bottom-left-link-three"/>
                    </Typography> */}
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="extraSmall" color="primary.light">
                        © Koninklijke Philips N.V. 2004 - {new Date().getFullYear()}. <FormattedMessage id="footer.bottom-right-copyright"/>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};


export default Footer;
