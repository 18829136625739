import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoryblok, renderRichText } from "@storyblok/react";
import { SET_MAINTENANCE } from 'store/actions';
import { useDispatch } from 'react-redux';

import config from 'config';

const useMaintenance = () =>  {
  const dispatch = useDispatch();
  const cc = config.currencyCode === 'CAD' ? 'ca' : 'us';
  const current_lang = useSelector((state) => state.customization.locale);

  const full_locale = cc === 'en' ? 'undefined' : `${current_lang}-${cc}`;

  const maint_for_this_site = 'admin/' + window.location.hostname.replace('www.','').replace(/\./g, '-');

  const [maintenance, setMaintenance] = useState({}); // we could use SWR or usePersistenState here ?
 
  const maint_info = useStoryblok(maint_for_this_site, { resolve_relations: "maintenance.category_order", language: full_locale });

  useEffect(() => {
    
    const rendered_message = renderRichText(maint_info?.content?.message);

    if (!maint_info?.content) {
      console.log('setting null');
      dispatch({ type: SET_MAINTENANCE, payload: null });
    } else {
      dispatch({ type: SET_MAINTENANCE, payload: {...maint_info.content, message: rendered_message}});
    }
    setMaintenance( {...maint_info?.content, message: rendered_message} )
  }, [dispatch, maint_info, current_lang]);

  return maintenance;
}

export default useMaintenance;