import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../../config';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    Button,
    useMediaQuery
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import * as actionTypes from 'store/actions';
import useAuth from 'hooks/useAuth';

// assets
import TranslateTwoToneIcon from '@mui/icons-material/TranslateTwoTone';

// ==============================|| LOCALIZATION ||============================== //

const LocalizationSection = ({nav}) => {
    
    // const { isLoggedIn } = useAuth();
    // const prefLanguages = window.navigator.languages.filter(locale => !locale.includes('-'));
    // const prefLanguagesAvailable = prefLanguages.filter(element => config.spokenLanguages.includes(element))

    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    // const [language, setLanguage] = useState(customization.locale);
    // const [language_to_pick, setLanguageToPick] = useState(customization.locale);

    const handleListItemClick = (lng) => {
        
        // setLanguage(lng);
        dispatch({ type: actionTypes.THEME_LOCALE, locale: lng });
        // dispatch({ type: actionTypes.THEME_LOCALE_DEFAULT, localeDefault: false });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    // const checkLanguage = (pref) => {
    //     if (prefLanguagesAvailable.includes(pref)){
    //         setLanguage(pref);
    //         dispatch({ type: actionTypes.THEME_LOCALE, locale: pref });
    //         dispatch({ type: actionTypes.THEME_LOCALE_DEFAULT, localeDefault: false });
    //     }
    //     return prefLanguagesAvailable.includes(pref)
    // }

    // useEffect(() => {
    //     if (customization.localeDefault && !isLoggedIn) {
    //         console.log('prefered: ' + prefLanguages)
    //         console.log('available: ' + prefLanguagesAvailable)
            
    //         if (prefLanguages.length > 0){
    //             prefLanguages.find(checkLanguage);
    //         } else {
    //             setLanguage('EN');
    //             dispatch({ type: actionTypes.THEME_LOCALE, locale: 'EN' });
    //             dispatch({ type: actionTypes.THEME_LOCALE_DEFAULT, localeDefault: false });
    //         }

    //     } else {
    //         if( config.spokenLanguages.includes(customization.locale) ){
    //             setLanguage(customization.locale);
    //         } else {
    //             setLanguage(prefLanguagesAvailable[0]);
    //             dispatch({ type: actionTypes.THEME_LOCALE, locale: prefLanguagesAvailable[0] });
    //             dispatch({ type: actionTypes.THEME_LOCALE_DEFAULT, localeDefault: false });
    //         }
    //     }
    // }, [customization]);
    
    if( config.spokenLanguages.length < 2 ){
        return(<></>); // nothing to choose !
    }

    if(!!nav && config?.spokenLanguages?.length === 2){
        return(<Box sx={{mx:2, gap:0}}>
            {config.spokenLanguages.map(lng => <Button variant="text" sx={{mx:0, p:0, minWidth: '32px', textTransform: 'uppercase'}}  key={lng} onClick={()=>handleListItemClick (lng)} disabled={lng === customization.locale}>{lng}</Button>)}
        </Box>);
    }   
        
    return (
        <>
            <Box
                // sx={{
                //     ml: 2,
                //     [theme.breakpoints.down('md')]: {
                //         ml: 1
                //     }
                // }}
            >
                {/* <pre>{JSON.stringify(config.spokenLanguages, null, 2)}</pre> */}
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            width: nav ? '45px' : '90px',
                            border: '2px solid',
                            marginRight: nav? '16px' : '',
                            borderColor: nav ? theme.palette.primary.main : theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                            background: nav ? theme.palette.primary.main : theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
                            color: nav ? theme.palette.primary.light : theme.palette.primary.dark,
                            transition: 'all .2s ease-in-out',
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                borderColor: nav ? theme.palette.primary.main : theme.palette.primary.light,
                                background: nav ? theme.palette.primary.light : theme.palette.primary.main,
                                color: nav ? theme.palette.primary.main : theme.palette.primary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        {/* <Typography variant="h5" sx={{ textTransform: 'uppercase' }} color="inherit">
                            {config.spokenLanguages.length === 2 ? <>{
                                config.spokenLanguages.filter(lng => lng != language)
                            }</>: <>{language}</>}
                        </Typography> */}
                        <Typography variant="h5" sx={{ textTransform: 'uppercase' }} color="inherit">
                            {customization.locale}
                        </Typography>
                        
                        {/* {language === 'en' && <TranslateTwoToneIcon sx={{ fontSize: '1.3rem' }} />} */}
                    </Avatar>
                </ButtonBase>
            </Box>
            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        minWidth: 200,
                                        maxWidth: 280,
                                        bgcolor: theme.palette.background.paper,
                                        borderRadius: customization.borderRadius,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250
                                        }
                                    }}
                                >
                                    {config.spokenLanguages.includes('en') && 
                                    <ListItemButton selected={customization.locale === 'en'} onClick={() => handleListItemClick('en')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">English</Typography>
                                                    <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (US)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>}
                                    
                                    {config.spokenLanguages.includes('fr') && 
                                    <ListItemButton selected={customization.locale === 'fr'} onClick={(event) => handleListItemClick('fr')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">Français</Typography>
                                                    <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (French)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>}
                                    
                                    {config.spokenLanguages.includes('es') && 
                                    <ListItemButton selected={customization.locale === 'es'} onClick={(event) => handleListItemClick('es')}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">Spanish</Typography>
                                                    <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (Españoles)
                                                    </Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default LocalizationSection;
