import { lazy } from 'react';

// project imports

import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const ContactUsPage = Loadable(lazy(() => import('views/pages/contact-us')));
const NotFoundPage = Loadable(lazy(() => import('views/pages/404')));
const ThankYouPage = Loadable(lazy(() => import ('views/pages/thankyou')));
const FaqPage = Loadable(lazy(() => import('views/pages/faq')));

// ==============================|| ROUTING available REGARDLESS AUTHENTICATED OR NOT ||============================== //

// there routes can be acccessed by both authenticated users as by quests

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout never_blocked={true} />,
    children: [
        {
            path: '/contact',
            element: <ContactUsPage />
        },  
        {
            path: '/faq/',
            element: <FaqPage />
        },                  
        {
            path: '/faq/:subject',
            element: <FaqPage />
        },          
        {
            path: '/thanks',
            element: <ThankYouPage />
        },
        {
            path: '*',
            element: <NotFoundPage />            
        } 
    ]
};

export default AuthenticationRoutes;
