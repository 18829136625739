const shop_currency = !!process.env?.REACT_APP_CURRENCY ? process.env.REACT_APP_CURRENCY : 'USD';
// const spokenLanguages = !!process.env?.REACT_APP_LANGUAGES ? process.env.REACT_APP_LANGUAGES.split('/') : ['en']; // ?? ['en', 'es'];
let spokenLanguages = ['en'];
 
const ext_links = {
    consumers: "https://www.usa.philips.com",
    dp_marketing: "https://www.usa.philips.com/c-m-pe/dental-professionals#_marketing_form",
    dp_sales: "https://www.usa.philips.com/c-m-pe/dental-professionals#_sales_form",
    about: "https://www.usa.philips.com/a-w/about.html"
};

if( shop_currency === 'CAD' ){
    spokenLanguages = ['en','fr'];
    ext_links.consumers =  "https://www.philips.ca/${locale}/c-e/pe/oral-healthcare.html";
    // TODO: find teh correct CAN version for these links
    ext_links.dp_marketing =  "https://www.philips.ca/${locale}/c-m-pe/dental-professionals#_marketing_form";
    // ext_links.dp_sales =  "https://www.philips.ca/${locale}/c-w/support-home/support-contact-page.html";
    ext_links.dp_sales =  "https://www.usa.philips.com/c-m-pe/dental-professionals#_sales_form";
    ext_links.about = "https://www.philips.ca/${locale}/a-w/about.html";
    
    ext_links.terms_of_use = "/${locale}/terms_of_use.pdf";
    ext_links.terms_and_conditions = "/${locale}/terms_and_conditions.pdf";

    ext_links.imported = {
        company: "Discus Dental Canada (DDC)",
        address: "2830 Argentia Road, Unit 728",
        city: "Mississauga, ON L5N 8G4",
        phone: "(800) 278-8282",
        href: "https://www.philips.ca/c-e/pe/dental-professionals.html" 
    };
    ext_links.produced = {
        company: "Discus Dental, LCC",
        address: "Ontario, CA 91761 USA",
        // phone: "(800) 278-8282",
        href: "https://www.usa.philips.com/c-m-pe/dental-professionals/" 
    };
    // banner should be available in two languages before we can use it in CAN
    ext_links.__banner_login = {
        imagepath: "Proshop-Module-Banner",
        colwidth : [7,4,1],
        url: "https://www.philips.ca/${locale}/c-e/pe/dental-professionals.html#_sales_form_ohc"
    };

} else { // only USA ...
    // ext_links.terms_of_use = "/${locale}/terms_of_use.pdf";
    ext_links.terms_and_conditions = "/us/terms_and_conditions.pdf";

    ext_links.banner_login = {
        imagepath: "Proshop-Module-Banner",
        colwidth : [7,4,1],
        url: "https://www.usa.philips.com/c-m-pe/dental-professionals#_sales_form"
    };
    ext_links.banner_shop = {
        imagepath: "Proshop-Module-Banner",
        colwidth : [7,4,1],
        url: "https://www.usa.philips.com/c-m-pe/dental-professionals#_sales_form"
    };

}

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/',
    fontFamily: `'NeueFreutiger World', sans-serif`,
    borderRadius: 6,
    outlinedFilled: true,
    theme: 'light', 
    presetColor: 'theme1',
    i18n: 'en',
    rtlLayout: false,
    spokenLanguages: spokenLanguages,
    currencyCode: shop_currency,
    support: {
        phone: shop_currency === 'CAD' ? '(800) 278-8282' : '800-422-9448',
        phone_link: shop_currency === 'CAD' ? 'tel:800-278-8282': 'tel:800-422-9448',
        email: shop_currency === 'CAD' ? 'pohc.customerservice@philips.com' : 'pohc.customerservice@philips.com' 
    },
    links: ext_links
};

export default config;
