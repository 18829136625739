/**
 * axios setup
 */

import axios from 'axios';

// start connected to QA
let baseURL = 'https://dentalshop-us.appspot.com/api/PHILIPSDENTALPRO-TST';

try {
    // EXCPTION FOR testing in prod
    if (window.location.host.endsWith('prd-0355-ent-bundles-dot-prod-dot-dentalshop-us.appspot.com')) {
        baseURL = 'https://dentalshop-us.appspot.com/api/PHILIPSDENTALPRO';
    }

    if (window.location.host.endsWith('proshop.philips.com')) {
        baseURL = 'https://dentalshop-us.appspot.com/api/PHILIPSDENTALPRO';
    }

    if (window.location.host.endsWith('proshop.philips.ca') || window.location.host == 'dentalshop-ca.nn.r.appspot.com') {
        baseURL = 'https://api-dot-dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO';
    }
    // QA shop
    if (window.location.host === 'localhost:3000') {
        baseURL = 'https://dentalshop-us.appspot.com/api/PHILIPSDENTALPRO';
        // baseURL = 'https://api-dot-dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO-TST'; // QA CAN
        // baseURL = 'https://pcdev-dot-dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO-TST';  // DEV AVN
    }
    if (window.location.host.endsWith('devshop-dot-dentalshop-us.appspot.com')) {
        // baseURL = 'https://dentalshop-us.appspot.com/api/PHILIPSDENTALPRO-TST';
        baseURL = 'https://pcdev-dot-dentalshop-us.appspot.com/api/PHILIPSDENTALPRO-TST';
    }
    // QA CAN
    if (window.location.host === 'localhost:3001') {
        baseURL = 'https://api-dot-dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO-TST';
    }
    if (window.location.host.endsWith('shop-dot-dentalshop-ca.appspot.com')) {
        baseURL = 'https://api-dot-dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO-TST';
    }
   
    // DEV CAN
    if (window.location.host.endsWith('devshop-dot-dentalshop-ca.appspot.com')) {
        // baseURL = 'https://dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO-TST';
        baseURL = 'https://pcdev-dot-dentalshop-ca.appspot.com/api/PHILIPSDENTALPRO-TST';
    }
    // local dev version
    if (window.location.host.startsWith('127.0.0.1:30')) {
        baseURL = 'http://localhost:8080/api/PHILIPSDENTALPRO-TST'
        // baseURL= 'http://localhost:8080/api/PHILIPSDENTALPRO'
    }


} catch (error) {
    console.error(error);
}

try {
    window.we_are_connected_to = baseURL.endsWith('-TST') ? 'QA' : 'PRD';
} catch (error) {
    console.error(error);
}

const axiosServices = axios.create({
    baseURL: baseURL
});



// interceptor for http
axiosServices.interceptors.response.use(
    (response) => {
        try {
            if (!!response.headers['x-upstream']) {
                window.we_are_connected_to = baseURL.endsWith('-TST') ? response.headers['x-upstream'] : 'PRD';
            }
        } catch (set_upstream_error) {
            console.warn(set_upstream_error);
        }
        return response;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
