// action - state management
import { LOGIN, LOGIN_AS, LOGOUT, REGISTER, RESET_PASSWORD, SET_LOGGED_IN_REDIRECT } from './actions';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    isSalesRep: false,
    salesRepCompany: null, // the PC company the sales rep used to login
    company: null,          // for which we order
    redirect: 'default'
};

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { company } = action.payload;
            return {
                ...state,
                company
            };
        }
        case LOGIN: {
            const { company, user_id, isSalesRep, order_for_company } = action.payload;
            // if we receive info from token, this is still a string!
            let rep = (isSalesRep === 'true' || isSalesRep === true) ? true : false;

            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                isSalesRep: rep,
                salesRepCompany: !!rep ? company : null,
                user_id,
                company: !!rep ? order_for_company : company
            };
        };
        case LOGIN_AS: {
            return {
                ...state,
                company: action.payload.order_for_company
            };
        }
        case SET_LOGGED_IN_REDIRECT:{
            return {
                ...state,
                redirect: action.redirect
            };
        }
        case LOGOUT: {
            let new_state = {...state};
            new_state.isInitialized = true;
            new_state.isLoggedIn = false;
            new_state.company = null;
            new_state.isSalesRep = false;
            new_state.salesRepCompany = null;
            new_state.user_id = null;
            // console.log(new_state);
            return {
                new_state
            };
        }
        case RESET_PASSWORD: {
            const { response } = action.payload;
            return {
                ...state,
                response
            }
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
