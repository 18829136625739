import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';

// project imports
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import OrderOnBehalfBar from 'ui-component/OrderOnBehalfBar';
import useMaintenance from 'hooks/useMaintenance';
import MaintenancePage from 'views/pages/maintenance';
import BlockingLoader from 'ui-component/BlockingLoader';
// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const maintenance = useMaintenance();
    return (
        <>
            <TopNavigation />
            <OrderOnBehalfBar />
            {/* main content */}
            <Main theme={theme}>
                {!maintenance ? <BlockingLoader text="Loading ..." /> : !!maintenance?.maintenance_mode ? <MaintenancePage/> : <Outlet />}
            </Main>

            <Footer />
        </>
    );
};

export default MainLayout;
