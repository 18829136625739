// action - state management
import { PrintDisabled } from '@mui/icons-material';
import * as actionTypes from './actions';

export const initialState = {
    checkout: {
        step: 0,
        products: [],
        subtotal: 0,  // total price of products in cart
        total: 0,     // total of order incl shipping and discount !?
        discount: 0,
        shipping: 0,
        addresskey: null,
        payment: {
            type: 'creditcard',
            method: 'cod',
            card: ''
        }
    }
};

// ==============================|| E-COMMERCE REDUCER ||============================== //

const cartReducer = (state = initialState, payload) => {

    // TODO: why do we have these in the global scope ?

    let latestProducts;
    let newShipping;

    // TODO: either use ADD_PROD / DEL_PROD to add and remove 1 product at a time and call multiple times of needed
    //   OR: always user an UPDATE_QUANITY that can be used even if a product is not in the cart yet

    switch (payload.type) {

        case actionTypes.UPDATE_QUANTITY:
        // case actionTypes.UPDATE_BUNDLE:
            
            // expect payload.product and payload.quantity it may be zero or the product may be new
            let updated_products = [...state.checkout.products];
            const prod = payload.product;
            if (!prod.id){
                prod.id = `${prod.part_no}`;
            }
            const existing_prod = updated_products.find(pr => pr.id === prod.id);
            // const existing_prod = updated_products.find(pr =>
            //     // eslint-disable-next-line eqeqeq
            //     pr.part_no === prod.part_no && ((!pr.active_bundle && !prod.active_bundle) || (!!pr.active_bundle && !!prod.active_bundle && pr.active_bundle.code == prod.active_bundle.code && pr.active_bundle.grp == prod.active_bundle.grp))
            // );
            
            let q_attr = prod.active_bundle ? 'quantity_requested' : 'quantity'
            if( payload.quantity === 'requested'){
                payload.quantity = !!existing_prod ? existing_prod.quantity_requested : 0;
                q_attr = 'quantity';
            }

            if (!existing_prod) {
                updated_products.push({ ...prod, [q_attr]: payload.quantity })
            } else {
                if(payload.quantity < 0){
                    existing_prod.quantity = 0;
                    existing_prod.quantity_requested = 0;
                } else {
                    existing_prod[q_attr] = payload.quantity;
                }
                if( payload.max_qty ){
                    existing_prod.max_qty = payload.max_qty;
                }
                if( payload.PackageQty ) existing_prod.PackageQty = payload.PackageQty;
            }
            if (payload.quantity <= 0) {
                updated_products = updated_products.filter(pr => pr.id !== prod.id);
            }

            // cart is updated now recalc the totals ...
            let new_product_total = 0;
            for (const item of updated_products) {
                new_product_total += item[q_attr] * Number(item.price);
            };

            let new_discount = state.checkout.discount;
            if (new_product_total < state.checkout.subtotal) {
                new_discount = 0; // reset discount of we remove items
            };

            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    products: updated_products,
                    subtotal: new_product_total, // TODO: rename to product_total ?
                    total: (new_product_total + state.checkout.shipping) - new_discount, // TODO: rename to order_total ?
                    discount: new_discount
                }
            };
            
        case actionTypes.RECALCULATE_CART:
            if (!!payload.order && !!payload.order.items) {
                const items = payload.order.items.map((cp) => ({...cp, id: !cp.package_skey ? `${cp.WebPartNbr}` : `${cp.package_skey}_${cp.package_code}_${cp.package_group}_${cp.WebPartNbr}`})); // make prod,id UNIQUE
                latestProducts = state.checkout.products.map((prod) => {
                    const item_is_in_checkout = items.find(item => item.id === prod.id);
                    
                    if (item_is_in_checkout) {
                        // also correct price and quantity
                        prod.PartPrice = Number(item_is_in_checkout.PartPrice);
                        prod.price = Number(item_is_in_checkout.PartPrice);
                        prod.quantity = Number(item_is_in_checkout.Quantity);
                        prod.status = Number(item_is_in_checkout.Quantity) < Number(prod.quantity) ? 'CAN_ORDER_PART' : 'CAN_ORDER_ALL';
                    } else {
                        prod.Quantity = 0;
                        prod.status = 'CANNOT_ORDER'; // seems out of stock ....
                    }
                    return prod
                });
            } else {
                latestProducts = state.checkout.products;
            }

            let product_total = 0;
            for (const prod of latestProducts) {
                product_total += Number(prod.price) * prod.quantity;
            }
            const calc_discount = !!payload.order.discount ? payload.order.discount : 0;
            // TODO: WHY !?
            // if (!!payload.order.discount) {
            //     count = count - payload.order.discount;
            // }

            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    order_nr: payload.order.order_nr, // remember this to speed u pthings at the API server
                    products: latestProducts,
                    subtotal: product_total,
                    total: product_total - calc_discount,
                    discount: calc_discount
                }
            }
        case actionTypes.SET_STEP:
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    step: payload.step
                }
            };
        case actionTypes.BACK_STEP:
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    step: state.checkout.step - 1
                }
            };
        case actionTypes.NEXT_STEP:
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    step: state.checkout.step + 1
                }
            };
        case actionTypes.SET_SHIPPING_ADDRESS:
            const address_skey = payload.address;
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    addresskey: address_skey
                }
            };
        case actionTypes.SET_DISCOUNT:

            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    code: payload.code,
                }
            };
        case actionTypes.SET_SHIPPING_CHARGE:
            newShipping = 0;
            if (payload.charge > 0) {
                newShipping = payload.charge;
            }
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    shipping_method: payload.method || 'Standard',
                    shipping: newShipping,
                    total: state.checkout.subtotal - state.checkout.discount + newShipping,
                    payment: {
                        ...state.checkout.payment,
                        type: payload.charge
                    }
                }
            };
        case actionTypes.SET_PAYMENT_METHOD:
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    payment: {
                        ...state.checkout.payment,
                        method: payload.method
                    }
                }
            };
        case actionTypes.SET_PAYMENT_CARD:
            return {
                ...state,
                checkout: {
                    ...state.checkout,
                    payment: {
                        ...state.checkout.payment,
                        card: payload.card
                    }
                }
            };
        case actionTypes.RESET_CART:
            return {
                ...state,
                checkout: {
                    step: 0,
                    products: [],
                    subtotal: 0,
                    total: 0,
                    discount: 0,
                    shipping: 0,
                    addresskey: null,
                }
            };
        default:
            return state;
    }
};

export default cartReducer;
